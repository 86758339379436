<template>
  <div>
    <!-- Slider main container -->
    <div class="swiper-container">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide">
          <img src="@/assets/img/fugu/1.png">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/fugu/2.png">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/fugu/3.png">
        </div>
      </div>
      <!-- <div class="bg">
      </div> -->
      <!-- If we need pagination -->
      <div class="swiper-pagination"></div>
    </div>
    <div class="goods">
      <div
        ref="box"
        class="item"
        v-for="(item,index) in imgRes"
        :key="index"
      >
        <div
          :class="isActive===index?'active bg-box':'bg-box'"
          :style="{height:heightNum+'px'}"
          @mouseenter="handleEnter(index)"
          @mouseleave="handleLeave"
          @click="handleRoute(item.id)"
        >
          <div
            class="bg default"
            :style="{background:'url('+item.defalut+') no-repeat center',backgroundSize:'cover'}"
          ></div>
          <div
            class="bg active"
            :style="{background:'url('+item.active+') no-repeat center',backgroundSize:'cover'}"
          ></div>
          <div class="name">{{item.enName}}</div>
        </div>
        <div class="box-b">
          <div class="desc">
            <div class="cname">{{item.zhName}}</div>
            <div class="info">{{item.intro}}</div>
          </div>
          <div
            class="btn"
            @click="handleRoute(item.id)"
          >Learn More</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img
        class="bg"
        src="@/assets/img/Y2K系列素材/Y2K.jpg"
        alt=""
      >
    </div>
  </div>
</template>

<script>
// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/swiper-bundle.css';
// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/core';
// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);
import { fuguDetail } from './detail'

export default {
  data() {
    return {
      isActive: '',
      widthNum: 0,
      imgRes: [],
    }
  },
  computed: {
    heightNum() {
      return this.widthNum
    }
  },
  methods: {
    handleEnter(i) {
      this.isActive = i
    },
    handleLeave(i) {
      this.isActive = ''
    },
    handleRoute(id) {
      const name = '复古系列'
      this.$router.push({ name: 'detail', query: { id, name } })
    }
  },
  created() {
    this.imgRes = fuguDetail
  },
  mounted() {
    this.widthNum = this.$refs.box[0].clientWidth
    window.onresize = () => {
      this.widthNum = this.$refs.box[0].clientWidth
    }
    const swiper = new Swiper('.swiper-container', {
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      speed: 2000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        bulletClass: 'my-bullet',
        bulletActiveClass: 'my-bullet-active',
      },
    });
  }
}
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51rem;
  .bg {
    position: absolute;
    top: 50%;
    left: 50%;
    // width: 546px;
    // height: 240px;
    width: 21.5rem;
    height: 9.8rem;
    transform: translate(-50%, -50%);
    background: url("../../../assets/img/Y2K系列素材/Y2KTEXT.png") no-repeat
      center;
    background-size: cover;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
.goods {
  display: flex;
  justify-content: space-around;
  margin-bottom: 66px;
  flex-wrap: wrap;
  .item {
    width: 45%;
    margin-top: 61px;

    .bg-box {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      margin: 0 15px;

      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .bg.default {
        opacity: 1;
        transition: 0.4s;
      }
      .bg.active {
        transform: scale(1.1);
        opacity: 0;
        transition: 0.6s ease-out;
      }
      .name {
        position: absolute;
        top: 9px;
        left: 7px;
        color: #ffffff;
      }
    }
    .bg-box.active {
      .bg.default {
        opacity: 0;
      }
      .bg.active {
        transform: scale(1);
        opacity: 1;
      }
    }
    .box-b {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 14px;
      font-size: 12px;
      margin-right: 15px;
      margin-left: 15px;
      .btn {
        width: 89px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        font-size: 13px;
        border: 1px solid #0a0406;
        cursor: pointer;
      }
    }
  }
}
.bottom {
  position: relative;
  .bg {
    width: 100%;
    height: 100%;
  }
}
</style>