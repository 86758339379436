<template>
  <div class="msg">
    <div class="left">
      <p
        style="margin-bottom: 27px;"
        class="info"
      >
        酵色JOOCYEE将彩妆作为一种生活艺术和美学的载体，<br>
        希望借由探索不同主题在彩妆上的运用，<br>
        从东方视角诠释浪漫美学，<br>
        以专业理念定制适肤产品，<br>
        创造出更适合中国女性的“设计师彩妆”。
      </p>
      <!-- <div class="into">
        <div>
          <p style="border-bottom:1px dashed #979797;color: #777777;">中文名<span style="margin-left: 50px;color: #000000;">酵色</span></p>
          <p style="border-bottom:1px dashed #979797;color: #777777;">外文名<span style="margin-left: 50px;color: #000000;">Joocyee</span></p>
        </div>
        <div style="text-align: right;width: 35%">
          <p style="border-bottom:1px dashed #979797;color: #777777;">注册时间<span style="margin-left: 50px;color: #000000;">2019年</span></p>
          <p style="border-bottom:1px dashed #979797;color: #777777;">所属行业<span style="margin-left: 50px;color: #000000;">化妆品</span></p>
        </div>
      </div> -->
      <h2>品牌释义</h2>
      <p>Modern：东方视角，现代表达。</p>
      <p>Romance：感性浪漫，细腻诠释。</p>
      <p>Tailored：专业实用，为肤定制。</p>
      <div style="width: 500px">
        <h2>品牌故事</h2>
        <p style="line-height: 22px;">酵色于2019年创立于上海。酵色倡导的是一种传统和现代、东方和西方融合的摩登美感，希望在符合东方审美的基础上，融入多元的现代风格，这也来源于品牌在当下许多中国女性身上观察到的独特哲学——一种温柔不失力量，内敛兼具表达的态度。<br><br />
          酵色从女性视角出发，回归生活的细微之处，在一首诗、一朵花、一片月色中，发现属于中国女性含蓄细腻的浪漫表达。品牌将片刻灵感融入系列创意之中，赋予彩妆多一点生活方式，亦激发更多人对生活和美的灵感。<br><br>
          酵色希望在重新认识中国女性肤色、肤质和骨象的基础上，以设计思维去观察每一个人不同的面容，体察消费者需求，精准解决问题，为中国女性量肤裁定更适合自己的妆容解决方案。
        </p>
      </div>

      <h2>品牌产品系列</h2>
      <p style="font-weight: 600;"><span style="font-weight: 200; font-size: 15px;">
          <img
            style="vertical-align: baseline;height: 11px;"
            src="../assets/jarchive.png"
            alt=""
          >
        </span> 灵感档案系列线：</p>
      <p style="font-weight: 600; ">从设计师视角出发，探索生活、自然材质和时代风格之美</p>
      <p style="margin-top:1.56rem;">代表产品系列：</p>
      <p style="font-size: 12px;line-height: 22px;">
        1.琥珀系列：封存野生美感 <br />
        2.贝壳系列：收藏天然流光<br />
        3.丝绸系列：捕捉漂浮的绿<br />
        4.浮波系列：色彩随波荡漾<br />
        5.夏禅系列：做个梦，名叫无尽夏<br />
        6.游染系列：染上万物迷人之色<br />
        7.浮金系列：Welcome Back To 1990s<br />
        8.玫瑰情诗系列：玫瑰至上，浪漫寻常<br />
        9.猫眼联名系列：反转主角，即将登场<br />
        10.兰夜系列：心若兰兮，脉脉不语</p>

      <p style="font-weight: 600; margin-top: 1.56rem;"><span style="font-weight: 200; font-size: 15px;">
          <img
            style="vertical-align: baseline;height: 11px;"
            src="../assets/jtailored.png"
            alt=""
          >

        </span> 专研定制系列：</p>
      <p style="font-weight: 600; ">从设计师思维出发，探索更适合中国女性的妆容哲学</p>
      <p style="margin-top: 1.56rem;"> 代表产品系列：</p>
      <p style="font-size: 12px;line-height: 22px;">
        1.原生裸色系列：原生之美，回归肤色<br />
        2.唇部家族：唇部系列，多元选择<br />
        3.原生裸感气垫：有妆效，无妆感</p>

      <p style="font-weight: 600; margin-top: 25px;"><span style="font-weight: 200; font-size: 15px;">
          <img
            style="vertical-align: baseline;height: 11px;"
            src="../assets/jessential.png"
            alt=""
          >
        </span> 基础系列：</p>
      <p style="font-weight: 600; ">回归彩妆最基本的功能诉求，打造符合中国女性上妆习惯的实用基础彩妆</p>
      <p style="margin-top: 22px;"> 代表产品系列：</p>
      <p style="font-size: 12px;line-height: 22px;">
        1.洁颜油：平衡肤感与卸妆力的双优解<br />
        2.眉笔：双面勾勒，轻松描绘<br />
        3.润唇啫喱：滋润不起皮，养护更持久<br />
        4.唇部卸妆湿巾：轻松卸妆，告别染唇
      </p>
      <h2>橘宜集团</h2>
      <p>橘宜集团于2016年成立于上海，是中国领先的多品牌美妆集团。橘宜集团已成功打造橘朵和酵色两大彩妆品牌。</p>
      <p>* 橘宜集团为品牌公司，不涉及生产。</p>
    </div>
    <div class="right">
      <div class="msg1"></div>
      <div class="msg2"></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.msg {
  width: 100%;
  display: flex;
}
.left {
  flex: 1;
  padding: 8.063rem 0 5.063rem 5.063rem;

  .info {
    font-size: 17px;
    color: #000000;
    letter-spacing: 0;
    line-height: 25px;
    font-weight: 600;
    font-family: "SourceHanSans";
  }
  p {
    font-size: 14px;
  }
  h2 {
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 600;
    margin-top: 7.438rem;
    margin-bottom: 0.563rem;
    // font-family: SourceHanSansSC-Medium;
  }
}
.right {
  min-height: 100rem;
  min-width: 40.438rem;
  .msg1 {
    background: url("../assets/msg02.png") center center no-repeat;
    background-size: cover;
    min-width: 40.438rem;
    min-height: 50%;
  }
  .msg2 {
    background: url("../assets/msg01.png") center center no-repeat;
    background-size: cover;
    min-width: 40.438rem;
    min-height: 50%;
  }
}
.into {
  display: flex;
  div {
    width: 30%;
    // background: rosybrown;
    cursor: pointer;
  }
}
</style>