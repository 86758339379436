<template>
  <div class="msg">

    <div class="right"></div>
    <div class="left">
      <div class="into">
        <div>
          <p style="border-bottom:1px dashed #979797;color: #777777;">公司名称<span style="margin-left: 35px;color: #000000;">上海橘宜化妆品有限公司</span></p>
          <p style="border-bottom:1px dashed #979797;color: #777777;">外文名<span style="margin-left: 50px;color: #000000;">Joy Group</span></p>
        </div>
        <div style="width: 30%;text-align: right;">
          <p style="border-bottom:1px dashed #979797;color: #777777;">注册时间<span style="margin-left: 50px;color: #000000;width: 200px">2016年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
          <p style="border-bottom:1px dashed #979797;color: #777777;">所属行业<span style="margin-left: 50px;color: #000000;width: 200px">化妆品，护肤品</span></p>
        </div>
      </div>
      <h2>企业介绍</h2>
      <p>上海橘宜化妆品有限公司，简称橘宜集团，成立于2016年。</p>
      <p>目前公司业务包括品牌和研发生产两部分，已成功孵化和运营橘朵、酵色品牌，并将持续孵化和收购新的品牌业务。</p>
      <p>同时投资自有代工厂，持续投入创新研发体系，提升快速反应的供应链能力。</p>
      <h2>企业愿景</h2>
      <p>创造美丽 分享快乐</p>

      <h2>发展历程</h2>
      <p>2016年，创立于上海</p>
      <p>2017年，成功孵化橘朵品牌</p>
      <p>2018年，橘朵天猫官方旗舰店上线</p>
      <p>2019年，投资自有代工厂，拥有独立的产品研发和完善的生产能力</p>
      <p>2019年，创立酵色品牌</p>
      <p>2020年，橘朵开设第一家线下门店，实现线上线下全渠道布</p>
      <h2>获奖情况</h2>
      <p>2020年度京东奔跑计划年度黑马品牌</p>
      <p>2020年度天猫金妆奖-年度IP创新奖</p>
      <p>2020年度天猫金妆奖-年度唇釉</p>
      <p>2021年度抖音国货美妆周TOP 1品牌</p>
      <h2>旗下品牌</h2>
      <p>橘朵：融合专业与创新的新势力少女彩妆品牌，致力于为年轻消费群体打造个性与实用性兼备的彩妆产品。</p>
      <p>酵色：为中国新生代女性而生，希望借由探索不同主题在彩妆上的运用，创造出潮流和艺术感的“设计师彩妆”。</p>
      <h2>企业社会责任</h2>
      <p>2021年7月21日，上海橘宜化妆品有限公司向抗击暴雨灾情捐赠100万。</p>
      <p>2020年11-12月新冠疫情期间，上海橘宜化妆品有限公司向红十字会捐款100万元。</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.msg {
  width: 100%;
  display: flex;
}
.left {
  flex: 1;
  padding: 4.063rem 0 0 5.063rem;
  p {
    font-size: 14px;
  }
  h2 {
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 600;
    margin-top: 2.438rem;
    margin-bottom: 0.563rem;
    // font-family: SourceHanSansSC-Medium;
  }
}
.right {
  min-width: 40.438rem;
  background: url("../assets/msg2.png") center center no-repeat;
  //   background-size: cover;
  min-height: 52.563rem;
}
.into {
  display: flex;
  div {
    width: 35%;
    white-space: nowrap;

    // background: rosybrown;
  }
}
</style>